@import "../../styles/bs";
@import "bootstrap/scss/mixins/_gradients";
@import "bootstrap/scss/mixins/_border-radius";
@import "bootstrap/scss/_alert.scss";

.TR-reachability-table-in-group,
.TR-reachability-table-external {
  padding: 0.1em 0.25em;
  // margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

.TR-reachability-table-in-group {
  margin-right: 0.25em;
  margin-bottom: 0.25em;
}

.TR-reachability-good,
.TR-reachability-bad,
.TR-reachability-warning {
  @extend .alert;
  margin-bottom: 0.25em;
}

.TR-reachability-good {
  background-color: shift-color(map-get($theme-colors, "success"), $alert-bg-scale);
  border-color: shift-color(map-get($theme-colors, "success"), $alert-border-scale);
  color: shift-color(map-get($theme-colors, "success"), $alert-color-scale);
}

.TR-reachability-warning {
  background-color: shift-color(map-get($theme-colors, "warning"), $alert-bg-scale);
  border-color: shift-color(map-get($theme-colors, "warning"), $alert-border-scale);
  color: shift-color(map-get($theme-colors, "warning"), $alert-color-scale);
}

.TR-reachability-bad {
  background-color: shift-color(map-get($theme-colors, "danger"), $alert-bg-scale);
  border-color: shift-color(map-get($theme-colors, "danger"), $alert-border-scale);
  color: shift-color(map-get($theme-colors, "danger"), $alert-color-scale);
}

.TR-reachability-table {
  button {
    padding-top: 0;
    padding-bottom: 0;
  }

  .active {
    background-color: $table-active-bg;

    .TR-reachability-good,
    .TR-reachability-bad,
    .TR-reachability-warning {
      border-width: 2px;
      border-color: $input-focus-border-color;
    }
  }
}

.TR-reachability-table-popover {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;

    li {
      display: inline;
    }

    li + li::before {
      content: ", ";
    }
  }

  p {
    margin: 0;
    display: inline;
    margin-right: 0.25em;
  }
}

.TR-reachability-list-table {
  margin-top: 3em;

  .TR-reachability-good,
  .TR-reachability-warning,
  .TR-reachability-bad {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    margin: 0;
  }
}

.TR-reachability-form {
  #number-groups,
  #main-groups {
    display: block;
    margin-bottom: 0.5rem;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.TR-reachability-no-data {
  font-style: italic;
  color: $text-muted;
}

.TR-reachability-reload-button {
  background-color: transparent;

  svg {
    width: 1em;
    height: 1em;
  }
}

.TR-reachability-chart {
  margin-top: 3em;
}
