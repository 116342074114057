.TR-page-header {
  img {
    max-height: 2em;
    margin-top: -0.8125rem;
    margin-bottom: -0.8125rem;
  }

  .user-button,
  .user-button:active,
  .user-button:focus {
    background-color: transparent !important;
    color: inherit !important;
    border: none;
  }

  .dropdown {
    margin-left: auto;
  }
}

.TR-subnav {
  margin-top: 1rem;
  display: block;
}

.TR-sub-sub-nav {
  margin-top: 0.5em;
  margin-bottom: 1em;

  .btn-group + .btn-group {
    margin-left: 0.5em;
  }
}

html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;

  header {
    flex: 0 0 auto;
  }

  main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}
