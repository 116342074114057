.TT-reachability-users-list {
  .first td {
    border-bottom: none;
    padding-bottom: 0;
  }
  .second td {
    border-top: none;
    padding-top: 0;
    font-size: 0.8em;
  }
}
