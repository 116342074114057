.TR-number-group-form-number-list {
  list-style-type: none;
  padding-left: 0;

  li {
    display: grid;
    grid-template-columns: 1fr 1fr max-content;
    grid-gap: 1em;
    align-items: start;
    margin-bottom: 0.5em;
  }
}
