@import "../../../styles/bs";

.TR-fetch-more-button {
  border: 0;
  border-top: $border-width solid $border-color;
  background: inherit;
  display: block;
  width: 100%;
  color: $link-color;
  margin-bottom: 4em;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}
