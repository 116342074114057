@import "bootstrap/scss/bootstrap.scss";

@import "./login-screen";
@import "./layout";

.TR-enroll-otp-modal {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
