.TR-loading-screen {
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 5em;
  color: #000;

  svg {
    width: 100%;
    height: 100%;
  }

  &.stretch svg {
    height: 50vh;
    width: 50vh;
  }

  &.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  &.absolute {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  &.translucent,
  &.backdrop.translucent {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
