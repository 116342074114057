.TR-number-list {
  li {
    ul {
      display: inline;
      list-style-type: none;
      padding-left: 0.5em;

      li {
        display: inline;
      }
      li + li::before {
        content: ",";
        padding-right: 0.5em;
      }
    }
  }
}
