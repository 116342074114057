@import "../../styles/bs";

.TR-form-actions-container {
  margin-top: 4em;
  margin-bottom: 2em;
  padding-top: 1em;
  border-top: $border-width solid $border-color;

  .btn {
    margin-right: 0.5em;
  }
}
